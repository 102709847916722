$(function () {
	$("#calcShipping").on('click', function (evt) {
		evt.preventDefault();

		$('#shipping').text('');
		
		var cep = $('#cep').val();
		if(cep=='') return false;
		
		cep = cep.replace('-', '');

		var data = {
			ceporigem: CheckoutService.ceporigem,
			cepdestino: cep.substr(0,8),
			products: CheckoutService.products
		}

		$('#noLoader').hide();
		$('#loaderCEP').show();
		$.post('/shipping', data)
			.done(function (res) {
				setOptions(res, cep.substr(0,8));
			})
			.fail(function (err) {
				var msg = err.responseJSON && err.responseJSON.message || 'Ocorreu um erro. Tente novamente mais tarde.';
			})
			.always(function () {
				//
			});
	});

	$('.checkout-button-fake').click(function(){
		$('#modalFrete').modal('show');
	})

	function setOptions(items, cepdestino){
		let options = '';
		$.each(items, function(k, v){
			let prazo = ( v.valor == '0,00' || v.valor == '0.00' || v.prazo == '0' ) ? '' : ' - Prazo: ' + v.prazo + " dia(s)";
			let valor = ( v.valor == '0,00' || v.valor == '0.00' ) ? '' : "R$ " + (v.valor.toFixed(2));
			options += "<label style='margin-bottom:15px'>" + v.title + " <input onclick=\"showCheckoutForm('" + v.name + "'," + v.valor + "," + cepdestino + ")\" type=\"radio\" name=\"frete\" value=\"" + v.title + "-" + v.valor + "\"> <br/><small style=\"color:#555 !important; font-weight: 300\">" + valor + prazo  + "</small>&nbsp;&nbsp;&nbsp;&nbsp;</label><br/>";
		});
		$('#shipping').append(options);
		$('#loaderCEP').hide();
		$('#noLoader').show();
	}

	$('.checkout-button').hide();

});

window.showCheckoutForm = function (n,v, c){
	$('.checkout-button-fake').hide();
	$('.checkout-button').show();
	let price = parseFloat(CheckoutService.totalPriceCart)+parseFloat(v);
	$('#totalCart').html('<h4><b>'+ (price.toFixed(2)).replace('.', ',') +'</b></h4>');
	let shipp = '<input type="hidden" id="nameShipping" name="nameShipping" value="'+n+'" /><input type="hidden" name="cepDestino" id="cepDestino" value="'+c+'" />';
	$('.inputShipping').html(shipp);
}