$(function () {

	$.ajaxSetup({
		headers: {
			'X-CSRF-TOKEN': window.Laravel.csrfToken
		}
	});

	$('.owl-carousel-unique').owlCarousel({
		loop:true,
		margin:10,
		nav:true,
		autoplay:true,
		autoplayTimeout: Number(window.Settings.banners_timeout),
		autoplayHoverPause:false,
		navText: ['<i class="fa fa-lg fa-chevron-left"></i>', '<i class="fa fa-lg fa-chevron-right"></i>'],
		responsive:{
			1000: {
				items: 1
			},
			0: {
				items: 1
			},
			600: {
				items: 1
			}
		}
	});

	$('.owl-carousel-multiple').owlCarousel({
		loop:true,
		margin:10,
		nav:false,
		autoplay:true,
		autoplayTimeout: Number(window.Settings.carousel_timeout) || 3000,
		responsive:{
			0:{
				items: 1
			},
			600:{
				items: 3
			},
			1000:{
				items: Number(window.Settings.carousel_home_qty) || 4
			}
		}
	});

	$('.owl-carousel-multiple-categories').owlCarousel({
		loop:true,
		margin:10,
		nav:false,
		autoplay:true,
		autoplayTimeout: Number(window.Settings.carousel_timeout) || 3000,
		responsive:{
			0:{
				items: 1
			},
			600:{
				items: 3
			},
			1000:{
				items: Number(window.Settings.categories_home_qty) || 4
			}
		}
	});

	$('.group-images').magnificPopup({
        delegate: 'a',
        type: 'image',
        gallery: {
            enabled: true
        },
    });

	$('.group-owl-images').magnificPopup({
        delegate: '.owl-item:not(.cloned) a',
        type: 'image',
        gallery: {
            enabled: true
        },
    });
});