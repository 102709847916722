$(function () {
	var checkoutButtons = $('.checkout-button').find('button');
	var btnOriginalContentHTML = $('.checkout-button').find('button').html();
	var btnLoadingStateHTML = "<i class='fa fa-refresh fa-spin fa-lg fa-fw'></i> Aguarde ...";

	$('.checkout-button').on('submit', function (evt) {
		evt.preventDefault();
		var action = $(this).attr('action');
		var data = {
			cepDestino: document.getElementById('cepDestino').value,
			shipping: document.getElementById('nameShipping').value
		};
		btnLoadingState();

		$.post(action, data)
			.done(function (res) {
				console.log(res.code)
				var isOpen = PagSeguroLightbox({code: res.code}, {
					success: function (transactionCode) {
						btnOriginalState();
						window.location.href = "/checkout/success?tc=" + transactionCode + '&m=' + data.shipping
					},
					abort: function() {
						btnOriginalState();
					}
				});
				if(!isOpen) {
					window.location.href="https://pagseguro.uol.com.br/v2/checkout/payment.html?code="+res.code;
				}
			})
	});

	function btnLoadingState() {
		checkoutButtons.addClass('disabled').html(btnLoadingStateHTML)
	}

	function btnOriginalState() {
		checkoutButtons.removeClass('disabled').html(btnOriginalContentHTML);
	}
});